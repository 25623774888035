import React from 'react';
import { Container, Grid, Header } from 'semantic-ui-react';
import './style.css';

const speakersList = [
  {
    image: require('./images/datome.png'),
    title: "Gigi Datome",
    subtitle: "Ex capitano Nazionale di basket"
  },
  {
    image: require('./images/fantini.png'),
    title: "Sara Fantini",
    subtitle: "Primatista italiana del martello"
  },
  {
    image: require('./images/ravelli.png'),
    title: "Arianna Ravelli",
    subtitle: "Corriere della Sera"
  },
]

const Speakers = props => (
  <Container fluid className="speakers-container">
    <Container className="speakers-container">
      <h2 className="speakers-container-title">
        Relatori
      </h2>
      <Grid centered>
        {speakersList.map(speaker => (
          <Grid.Column computer={5} tablet={16} mobile={16} className="speaker-container">
            <div style={{ minWidth: 112 }}>
              <img src={speaker.image} style={{maxWidth: "100%"}} />
            </div>
            <div className="titles">
              <div className="speaker-title">
                {speaker.title}
              </div>
              <div className="speaker-subtitle" dangerouslySetInnerHTML={{__html: speaker.subtitle}}>
              </div>
            </div>
          </Grid.Column>
        ))}
      </Grid>
    </Container>
  </Container>
  )
  
export default Speakers;