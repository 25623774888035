import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import './style.css';

const Footer = props => (
  <Container fluid className="footer">
    <Container>
      <hr />
      <Grid>
        <Grid.Column computer="8" mobile="16">
          <a href="https://www.fondazionecorriere.corriere.it/privacy-policy/">
            <div className="text">Privacy Policy</div>
          </a>
        </Grid.Column>
        <Grid.Column computer="8" mobile="16" className="right-col">
          <a href="https://www.amicidiscuola.com">
            <div className="text separator">Amici di Scuola</div>
          </a>
          <a href="https://www.fondazionecorriere.corriere.it">
            <div className="text">Fondazione Corriere della Sera</div>
          </a>
        </Grid.Column>
      </Grid>
    </Container>
  </Container>
)

export default Footer;