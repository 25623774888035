import React from 'react';
import { Container } from 'semantic-ui-react';
import './style.css';
import esselunga from './esselunga.png';
import amici from './amici-di-scuola.png';
import corriere from './logo-fondazione-corriere.png';

const Header = props => (
  <Container fluid className="header">
    <Container className="logo-container">
      <div>
      <img src={esselunga} />
      </div>
      <div>
      <img src={amici} />
      </div>
      <div>
      <img src={corriere} />
      </div>
    </Container>
  </Container>
)

export default Header;