import React, { Component } from 'react'
import { Checkbox, Image, Button, Form, Grid, Message, Segment, Dimmer, Loader, Container } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import 'whatwg-fetch'
import Speakers from './Speakers'
import History from './History'
import Footer from './Footer'
import Header from './Header'
import Logos from './Logos'
import visual from './visual.png'

export default class Login extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      error: null,
      name: "",
      school: "",
      city: "",
      company: "",
      redirect: (localStorage.getItem("jwtFinal") !== null)
    }
  }
  
  login () {
    this.setState({loading: true})

    var headers = new Headers()
    headers.append("Content-Type", "application/json")

    var bodyObject = {
      name: this.state.name,
      school: this.state.school,
      city: this.state.city,
    }

    fetch('https://rcs-scuola47-api.reportcongressi.com/register', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(bodyObject)
    }).then(function(res) {
      if (res.ok) {
        res.json().then(function (data) {
          localStorage.setItem("jwtFinal", data.jwtToken)
          this.setState({redirect: true, loading: false})
        }.bind(this)).catch(function(err) {
          console.log(err)
        })
      } else {
        res.json().then(function (data) {
          this.setState({error: data.reason, loading: false})
        }.bind(this)).catch(function(err) {
          console.log(err)
        })
      }
    }.bind(this)).catch(function(err) {
      this.setState({loading: false, error: "Errore inaspettato, ricarica la pagina per riprovare."})
    }.bind(this))
    /*setTimeout(function () {
      this.setState({
        loading: false,
        error: true
      })
    }.bind(this), 3000)*/
  }

  render () {
    return (
      <div className='login-form'>
      {this.state.redirect && (
        <Redirect to="/" />
      )}
      {/*
        Heads up! The styles below are necessary for the correct render of this example.
        You can do same with CSS, the main idea is that all the elements up to the `Grid`
        below must have a height of 100%.
      */}
      <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        }
      `}</style>
      <Header />
      <Container fluid className="fluid-form-container">
        <Container>
          <Grid
            centered
            textAlign='center'
          >
            <Grid.Column computer={16} tablet={16} mobile={16} style={{ marginTop: "2rem" }}>
              <div>
                <img src={visual} style={{width: "100%"}} />
              </div>
            </Grid.Column>
            <Grid.Column computer={16} tablet={16} mobile={16} style={{ marginTop: "1rem" }}>
              <Segment className="login-container">
                <Grid>
                  <Grid.Column computer={8} mobile={16} className="space-between">
                    <div>
                      <h3 className="time">23 aprile 2024, ore 11</h3>
                      <h1 className="title"><b>I VALORI DELLO SPORT</b></h1>
                    </div>
                    <div className="login-header-note">
                      Compila i dati per seguire la diretta Online
                    </div>
                  </Grid.Column>
                  <Grid.Column computer={8} mobile={16}>
                    <Form size='large' onSubmit={this.login.bind(this)}>
                      {this.state.loading && (
                        <Dimmer active>
                          <Loader />
                        </Dimmer>
                      )}
                      {this.state.error && (
                        <Message negative>
                          <p>{this.state.error}</p>
                        </Message>
                      )}
                      <Form.Input
                        fluid
                        placeholder="Classe o Nome"
                        icon="id badge"
                        iconPosition="left"
                        value={this.state.name}
                        onChange={e => this.setState({name: e.target.value})}
                      />
                      <Form.Input
                        fluid
                        placeholder="Scuola"
                        icon="id badge"
                        iconPosition="left"
                        value={this.state.school}
                        onChange={e => this.setState({school: e.target.value})}
                      />
                      <Form.Input
                        fluid
                        icon="id badge"
                        iconPosition="left"
                        placeholder="Città"
                        value={this.state.city}
                        onChange={e => this.setState({city: e.target.value})}
                      />
                      <Button className="login-button" color='blue' fluid size='large'>PARTECIPA</Button>
                    </Form>
                  </Grid.Column>
                </Grid>
              </Segment>
              <div className="privacy-note">Fornendo il mio nome dichiaro di aver preso visione dell’<a href="https://www.fondazionecorriere.corriere.it/privacy-policy/">Informativa Privacy</a> redatta ai sensi del Regolamento UE 679/2016 (“GDPR”)</div>
            </Grid.Column>
          </Grid>
        </Container>
      </Container>
      <Speakers />
      <Footer />
    </div>
    )
  }
}